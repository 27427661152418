import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { blankLessonPage } from './instructorSlice';

// use lessonId to get lesson page information from backend
export const loadLessonPage = createAsyncThunk(
    'instructor/loadLessonPage',
    async (lessonId: string, {getState}) => {
      //get user state to access token
      const stateData:any = getState();
      const {user} = stateData.user.data;
  
      let token;
      if ('token' in user) {  token = user.token } 
  
      if (token) {
        //define config
        const config = { headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }}

        //get env var
        const res = await fetch('/api/env/backend-url')
        const {backendUrl} = await res.json();
        if (!backendUrl) {
            console.error('Backend URL env is missing');
            return blankLessonPage
        }
        
        //load from backend api
        const response = await axios.get(
          backendUrl + '/api/lesson/get-instructor-lesson/'+lessonId +"/",
          config
        )
        return response.data
      } else {
        console.log('no token in user slice')
        return blankLessonPage
      }
    }
  )


interface patchLessonProps {
    [key:string]: any
}
export const patchLesson = createAsyncThunk(
    'instructor/patchLesson',
    async (submitData:patchLessonProps, {getState}) => {
        
        //get user state to access token
        const stateData:any = getState();
        const {user} = stateData.user.data;
        const {profile, lessonPage} = stateData.instructor.data
        const {lessonId} = lessonPage
    
        let token;
        if ('token' in user) {  token = user.token } 

        if (token) {
            //patch lesson
            const config = { headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${token}`
            }}

            //get env var
            const res = await fetch('/api/env/backend-url')
            const {backendUrl} = await res.json();
            if (!backendUrl) {
                console.error('Backend URL env is missing');
                return blankLessonPage
            }

            const response = await axios.patch(
                backendUrl + '/api/organizer/lesson/update-lesson/'+lessonId +"/",
                submitData,
                config
            )
            
            return response.data
        } else {
            console.log('no token in user slice')
            return blankLessonPage
        }
    }
)

  

export const deleteLesson = createAsyncThunk(
    'instructor/deleteLesson',
    async (lessonId: string, {getState}) => {
        //get user state to access token
        const stateData:any = getState();
        const {user} = stateData.user.data;
        const {instructorData} = stateData.instructor.data
    
        let token;
        if ('token' in user) {  token = user.token } 

        if (token) {
            //patch lesson
            const config = { headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }}

            //get env var
            const res = await fetch('/api/env/backend-url')
            const {backendUrl} = await res.json();
            if (!backendUrl) {
                console.error('Backend URL env is missing');
                return blankLessonPage
            }

            const response = await axios.delete(
                backendUrl + '/api/organizer/lesson/delete-lesson/'+lessonId +"/",
                config
            )
            //reset lesson redux to initial
            return blankLessonPage

        } else {
            console.log('no token in user slice')
            return blankLessonPage
        }
    }
)
